<template>
    <div>
        <v-data-table
                :headers="headers"
                :items="PurchaseOrderItems"
                calculate-widths
                class="mt-3 appic-table-light specification-table"
                dense
                disable-pagination
                disable-sort
                hide-default-header
                hide-default-footer
                item-key="index"
                single-select
        >
            <template v-slot:header="{ props: { headers } }">
                <tr>
                    <th class="grey lighten-3 pa-1 text-left width-1-pct">{{ headers[0].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left">{{ headers[1].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left width-7-pct">{{ headers[2].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left">{{ headers[3].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left width-15-pct">{{ headers[4].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left width-8-pct">{{ headers[5].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left width-8-pct">{{ headers[6].text  + (Purchaseorder__Contract__currency_id != Purchaseorder__currency_id ? '(' + getCurrency(Purchaseorder__Contract__currency_id) + ')' : '')}}</th>
                    <th class="grey lighten-3 pa-1 text-left width-8-pct">{{ headers[7].text + (Purchaseorder__Contract__currency_id != Purchaseorder__currency_id ? '(' + getCurrency(Purchaseorder__Contract__currency_id) + ')' : '') }}</th>
                    <th class="grey lighten-3 pa-1 text-left width-8-pct" v-if="Purchaseorder__Contract__currency_id != Purchaseorder__currency_id">{{ headers[8].text + '(' + getCurrency(Purchaseorder__currency_id) + ')' }}</th>
                    <th class="grey lighten-3 pa-1 text-left width-8-pct" v-if="Purchaseorder__Contract__currency_id != Purchaseorder__currency_id">{{ headers[9].text + '(' + getCurrency(Purchaseorder__currency_id) + ')' }}</th>
                    <th class="grey lighten-3 pa-1 text-left width-8-pct" v-if="Purchaseorder__Contract__currency_id != Purchaseorder__currency_id">{{ headers[10].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left width-1-pct" v-if="Purchaseorder__Contract__currency_id != Purchaseorder__currency_id">{{ $t('message.actions') }}</th>
                    <th class="grey lighten-3 pa-1 text-left width-8-pct" v-if="Purchaseorder__Contract__currency_id == Purchaseorder__currency_id">{{ headers[8].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left width-1-pct" v-if="Purchaseorder__Contract__currency_id == Purchaseorder__currency_id">{{ $t('message.actions') }}</th>
                </tr>
            </template>
            <template v-slot:item.lineNumber="{ item }">
                <div class="text-center mt-2 font-16px">{{ item.lineNumber }}</div>
            </template>
            <template v-slot:item.Salesitem.certification_id="{ item }">
                <div class="mt-2 font-16px">{{ getCertification(item.Salesitem.certification_id) }}</div>
            </template>
            <template v-slot:item.Species.title="{item}">
                <div class="mt-2 ml-0 font-16px">{{ item.Species.title }}</div>
            </template>
            <template v-slot:item.Spec.title="{item}">
                <div v-if="item.type == 'default'">
                    <div class="d-flex flex-row align-center" style="height: 35px">
                        <div style="min-width: 50px !important;" class="font-16px">{{ $t('message.product') + ":" }}</div>
                        <div class="font-16px">{{ getProductGroup(item.Salesitem.productgroup_id) }}</div>
                    </div>
                    <div class="d-flex flex-row align-center" style="height: 35px">
                        <div style="min-width: 50px !important;" class="font-16px">{{ $t('message.spec') + ":" }}</div>
                        <div class="font-16px">{{ item.Spec.title ? item.Spec.title : item.Salesdescription.title }}</div>
                    </div>
                    <div class="d-flex flex-row align-center" style="height: 35px" v-if="Purchaseorder__Contract__productgroup_id != 15">
                        <div style="min-width: 50px !important;" class="font-16px">{{ $t('message.mc') + ":" }}</div>
                        <div class="font-16px">{{ item.Mc.title }}</div>
                    </div>
                    <div class="d-flex flex-row align-center">
                        <div style="width: 50px !important;" class="font-16px">{{ $t('message.grade') + ":" }}</div>
                        <v-autocomplete
                            :items="allGrades"
                            :placeholder="$t('message.startTypingToSearch')"
                            :value="item.Salesitem.pograde_id"
                            class="custom pa-1 bg-lightgrey"
                            clearable
                            dense
                            hide-details
                            hide-no-data
                            item-text="Grade.title"
                            item-value="Grade.id"
                            @focus="currentRowIndex = item.index"
                            @change="updatePurchaseOrderItemRowHandle( item.index, 'Salesitem.pograde_id',$event)"
                        >
                            <template v-slot:selection="{ item }">
                                <div class="text-wrap" style="width: 100%">{{ item.Grade.title }}</div>
                            </template>
                        </v-autocomplete>
                    </div>
                    <div class="d-flex flex-row align-center" style="height: 35px">
<!--                        <div style="min-width: 50px !important;" class="font-weight-bold">{{ $t('message.descr') + ":" }}</div>-->
<!--                        <div>{{ item.Salesitem.description }}</div>-->
                        <div class="font-16px">{{ $t('message.specificationAsShownPurchaseOrder') + ": " + getUiDescription(item) }}</div>
                    </div>
                </div>
                <div v-if="item.type == 'othercharge'">
                    <v-text-field
                        :placeholder="$t('message.otherCharges')"
                        :value="item.Salesitem.size"
                        class="custom pa-1 force-text-left bg-lightgrey"
                        clearable
                        dense
                        hide-details
                        @change="updatePurchaseOrderItemRowHandle( item.index, 'Salesitem.size',$event)"
                    >
                    </v-text-field>
                </div>
            </template>
            <template v-slot:item.Size.title="{item}">
                <div class="mt-0 ml-0" v-if="item.type == 'default'">
                    <div class="d-flex flex-row align-center" style="height: 35px">
<!--                        <div style="width: 50px !important;" class="font-weight-bold">{{ $t('message.dim') + ":" }}</div>-->
                        <div class="font-16px">{{ item.Size.title }}</div>
                    </div>
                </div>
            </template>
            <template v-slot:item.Salesitem.poqty="{item}">
                <div style="height: 100%" v-if="item.type == 'default'">
                    <v-text-field
                        :id="item.index + 'poqty'"
                        :value="item.Salesitem.poqty"
                        class="custom pa-1 bg-lightgrey"
                        dense
                        hide-details
                        type="number"
                        @change="updatePurchaseOrderItemRowHandle( item.index, 'Salesitem.poqty',$event)"
                    >
                    </v-text-field>
                    <div class="font-weight-bold font-md d-flex flex-column justify-end font-16px" style="height: 34px;">{{ $t('message.unit') }}</div>
                    <v-select
                        :items="uofmQty"
                        :placeholder="$t('message.uofm')"
                        :value="item.Salesitem.pomeasurement_id"
                        class="text-right custom pa-1 bg-lightgrey"
                        dense
                        hide-details
                        item-value="Measurement.id"
                        item-text="Measurement.title"
                        reverse
                        @input="updatePurchaseOrderItemRowHandle( item.index, 'Salesitem.pomeasurement_id',$event)"
                        v-if="item.type == 'default'"
                    ></v-select>
                </div>
            </template>
            <template v-slot:item.Salesitem.costprice="{ item }">
                <v-text-field
                    :placeholder="$t('message.price')"
                    :value="item.Salesitem.costprice"
                    class="custom pa-1 bg-lightgrey"
                    dense
                    hide-details
                    type="number"
                    @change="updatePurchaseOrderItemRowHandle( item.index, 'Salesitem.costprice',$event)"
                    v-if="item.type == 'default'"
                >
                </v-text-field>
            </template>
            <template v-slot:item.Salesitem.amount="{ item }">
                <div class="mt-2 font-16px" v-if="item.type == 'default'">{{ formatThisNumber(item.Salesitem.poamount,currencyFormatPrecision) }}</div>
                <div class="d-inline-flex" v-if="item.type != 'default'" style="margin-right: -20px">
                    <v-icon small class="mb-3 red--text" v-if="item.type == 'credit'">mdi-minus</v-icon>
                    <v-text-field
                        :placeholder="$t('message.amount')"
                        :value="item.Salesitem.poamount"
                        class="custom pa-1 bg-lightgrey force-text-left"
                        dense
                        hide-details
                        type="number"
                        @change="updatePurchaseOrderItemRowHandle( item.index, 'Salesitem.amount',$event)"
                    >
                    </v-text-field>
                </div>
            </template>
            <template v-slot:item.Salesitem.costprice_converted="{ item }">
                <div class="mt-2 font-16px" v-if="item.type == 'default'">{{ formatThisNumber(item.Salesitem.costprice_converted, allCurrencies.find((currency)=>currency.Currency.id == Purchaseorder__currency_id).Currency.formatPrecision) }}</div>
            </template>
            <template v-slot:item.Salesitem.poamount_converted="{ item }">
                <div class="mt-2 font-16px" v-if="item.type == 'default'">{{ formatThisNumber(item.Salesitem.poamount_converted, allCurrencies.find((currency)=>currency.Currency.id == Purchaseorder__currency_id).Currency.formatPrecision) }}</div>
                <div class="mt-2 font-16px" v-else>{{ formatThisNumber(item.Salesitem.amount * Purchaseorder__currencyrate, allCurrencies.find((currency)=>currency.Currency.id == Purchaseorder__currency_id).Currency.formatPrecision) }}</div>
            </template>
            <template v-slot:item.Salesitem.itemqty="{item}">
                <div class="mt-2 font-16px" v-if="item.type == 'default'">{{ item.type != 'credit' ? formatThisNumber(item.Salesitem.itemqty,uofmPrecision(item.Measurement.title)) + ' ' + uofmQty.find((uofm)=>uofm.Measurement.id == item.Salesitem.measurement_id).Measurement.title : '' }}</div>
            </template>
            <template v-slot:item.index="{ item }">
                <div class="ml-2" v-if="[1,2].includes(item.Salesitem.chargeto) || item.type == 'othercharge'">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn icon @click="deleteItem(item.index, item.Salesitem.id)" v-on="on">
                                <v-icon color="grey">delete</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('message.deleteThisItem') }}</span>
                    </v-tooltip>
                </div>
                <div v-else class="text-center mt-2 font-weight-bold font-16px">{{ $t('message.na') }}</div>
            </template>
            <template v-slot:footer>
                <div class="pt-0 d-flex justify-space-between">
                    <div class="col-4 col-lg-4 col-md-5 pt-5 px-0 d-flex justify-start">
                        <v-btn
                            @click="addOtherChargesItemRow(statePrefix)"
                            color="success"
                            small
                        >{{ $t('message.addOtherCharges') }}
                        </v-btn>
                        <v-btn
                            @click.stop="specNotesDialog = true"
                            class="ml-2"
                            color="success"
                            small
                            v-if="false"
                        >{{ $t('message.addSpecificationNotes') }}
                        </v-btn>
                    </div>
                    <div class="dense-inputs col-8 col-lg-8 col-md-7 pa-0">
                        <v-row justify="end">
                            <v-col cols="2" lg="2" md="2" xs="3" class="px-1 pb-0">
                                <div class="text-right font-weight-black">{{ $t('message.totalPoQty') }}</div>
                                <div class="text-right font-weight-black d-flex flex-column">
                                    <span v-for="(total,index) in itemsTotalQty" :class="itemsTotalQty.length > 1 && index == 0  ? 'pt-1' : ''">
                                        {{ formatThisNumber(total.Total.qty,uofmPrecision(total.Total.uofm)) + ' '+ total.Total.uofm }}
                                    </span>
                                </div>
                            </v-col>
                            <v-col cols="1" lg="2" md="2" xs="3" class="px-1 pb-0">
                                <div class="text-right font-sm font-weight-black">{{ $t('message.poCurrency') }}</div>
                                <v-container class="ma-0 pa-0">
                                    <v-row no-gutters>
                                        <v-col cols="9" offset="3">
                                            <v-select
                                                    :items="allCurrencies"
                                                    class="custom py-0 mt-0 bg-lightgrey"
                                                    hide-details
                                                    item-text="Currency.code"
                                                    item-value="Currency.id"
                                                    reverse
                                                    v-model="Purchaseorder__currency_id"
                                            >
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-col>
                            <v-col cols="1" lg="2" md="2" xs="3" class="pl-1 pb-0" v-if="Purchaseorder__Contract__currency_id != Purchaseorder__currency_id">
                                <div class="text-right font-weight-black">{{ allCurrencies.find((currency)=>currency.Currency.id == Purchaseorder__Contract__currency_id).Currency.code + ':' + allCurrencies.find((currency)=>currency.Currency.id == Purchaseorder__currency_id).Currency.code + ' ' + $t('message.exRate') }}</div>
                                <v-container class="ma-0 py-0 pl-0 pr-0">
                                    <v-row no-gutters>
                                        <v-col cols="10" class="mr-0">
                                            <v-text-field
                                                :value="Purchaseorder__currencyrate"
                                                class="custom pa-1"
                                                dense
                                                type="number"
                                                @change="Purchaseorder__currencyrate = $event"
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="2" class="ml-0">
                                            <v-btn
                                                class="ml-2"
                                                height="30px"
                                                text
                                                width="30px"
                                                @click.stop="openForexView = true"
                                            ><v-img
                                                width="25px"
                                                height="25px"
                                                :src="appicUrls.find((url)=>url.key == 'maybankForexRates').icon"
                                            ></v-img>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-col>
                            <v-col cols="1" lg="2" md="2" xs="3" class="pl-1 pb-0">
                                <div class="text-right font-weight-black">{{ $t('message.totalCost') + (Purchaseorder__Contract__currency_id != Purchaseorder__currency_id ? '(' + allCurrencies.find((currency)=>currency.Currency.id == Purchaseorder__Contract__currency_id).Currency.code + ')' : '') }}</div>
                                <div class="text-right font-weight-black">{{ formatThisNumber(itemsTotalAmount, allCurrencies.find((currency)=>currency.Currency.id == Purchaseorder__currency_id).Currency.formatPrecision) }}</div>
                            </v-col>
                            <v-col cols="1" lg="2" md="2" xs="3" class="pl-1 pb-0" v-if="Purchaseorder__Contract__currency_id != Purchaseorder__currency_id">
                                <div class="text-right font-weight-black">{{ $t('message.totalAmount') + '(' + allCurrencies.find((currency)=>currency.Currency.id == Purchaseorder__currency_id).Currency.code +')'}}</div>
                                <div class="text-right font-weight-black">{{ formatThisNumber(itemsTotalAmountConverted, allCurrencies.find((currency)=>currency.Currency.id == Purchaseorder__currency_id).Currency.formatPrecision) }}</div>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </template>
        </v-data-table>
        <ForexView
            :url="appicUrls.find((url)=>url.key == 'maybankForexRates').url"
            namespace="purchaseorder"
        ></ForexView>
        <v-layout row>
            <v-flex xs12 lg6 md6 pl-3 py-1 class="align-center">
                {{ $t('message.specificationNotes') }}
                <v-textarea
                    :value="Purchaseorder__shipremark"
                    class="elevation-0 pt-3"
                    dense
                    hide-details
                    outlined
                    rows="5"
                    @change="Purchaseorder__shipremark = $event"
                ></v-textarea>
            </v-flex>
        </v-layout>
        <v-dialog
            overlay=false
            persistent
            scrollable
            transition="dialog-bottom-transition"
            v-model="specNotesDialog"
            v-if="false"
        >
            <v-card>
                <v-card-title class="success white--text">{{ $t('message.specificationNotes') }}</v-card-title>
                <v-card-text class="px-6">
                    <v-textarea
                        :value="Purchaseorder__shipremark"
                        class="elevation-0 pt-3"
                        dense
                        hide-details
                        outlined
                        rows="5"
                        @change="Purchaseorder__shipremark = $event"
                    ></v-textarea>
                </v-card-text>
                <v-card-actions class="px-6">
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="specNotesDialog = false"
                        class="ml-3"
                        color="default"
                        small
                    >{{ $t('message.close') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {mapActions, mapGetters, mapMutations} from "vuex";
    import {mapFields} from "vuex-map-fields";
    import {numberFormat} from "../../helpers/helpers";
    // import { debounce } from "debounce";
    // import { api } from "../../api";
    import {mapFieldsPrefixed, mapMultiRowFieldsPrefixed} from "../../helpers/vuex-map-fields-prefixed";
    // import ForexView from "./ForexView";

    const ForexView = () => import('./ForexView')

    export default {
        name: "PurchaseOrderItemListNew",
        props: ['updateMode'],
        components: {ForexView},
        data() {
            return {
                currentRowIndex: 0,
                dialogs: {
                    confirm:  false,
                    confirm_message: ""
                },
                specNotesDialog: false
            }
        },
        computed: {
            statePrefix(){
                if(this.updateMode){
                    return 'update'
                } else {
                    return 'current'
                }
            },
            ...mapFieldsPrefixed('purchaseorder', {
                Purchaseorder__Contract__currency_id: 'Purchaseorder.Contract.currency_id',
                Purchaseorder__Contract__is_export: 'Purchaseorder.Contract.is_export',
                Purchaseorder__Contract__ms_id: 'Purchaseorder.Contract.ms_id',
                Purchaseorder__Contract__salestype_id: 'Purchaseorder.Contract.salestype_id',
                Purchaseorder__Contract__productgroup_id: 'Purchaseorder.Contract.productgroup_id',
                Purchaseorder__contract_id: 'Purchaseorder.contract_id',
                Purchaseorder__id: 'Purchaseorder.id',
                Purchaseorder__currency_id: 'Purchaseorder.currency_id',
                Purchaseorder__currencyrate: 'Purchaseorder.currencyrate',
                Purchaseorder__shipremark: 'Purchaseorder.shipremark'
            },'statePrefix'),
            ...mapFields('purchaseorder',{
                newPurchaseOrderSaved: 'newPurchaseOrderSaved',
                newPurchaseOrderUnsaved: 'newPurchaseOrderUnsaved',
                updatedPurchaseOrderUnsaved: 'updatedPurchaseOrderUnsaved',
                updatedPurchaseOrderSaved: 'updatedPurchaseOrderSaved',
                openForexView: 'openForexView'
            }),
            ...mapMultiRowFieldsPrefixed('purchaseorderitem',{
                PurchaseOrderItems: 'PurchaseOrderItems',
            },'statePrefix'),
            ...mapFieldsPrefixed('purchaseorderitem',{
                itemsCurrencyRate: 'itemsCurrencyRate',
                itemsTotalAmount: 'itemsTotalAmount',
                itemsTotalAmountConverted: 'itemsTotalAmountConverted',
                itemsTotalQty: 'itemsTotalQty'
            },'statePrefix'),
            ...mapGetters('certification',{
                allCertifications: 'allCertifications'
            }),
            ...mapGetters('currency',{
                allCurrencies: 'allCurrencies'
            }),
            ...mapGetters('grade',{
                allGrades: 'allGrades',
                findGradeById: 'findGradeById'
            }),
            ...mapGetters('mc', {
                allMoistureContent: 'allMoistureContent'
            }),
            ...mapGetters('productgroup',{
                allProductGroups: 'allProductGroups'
            }),
            ...mapGetters('species',{
                allSpecies: 'allSpecies'
            }),
            ...mapGetters([
                'appicUrls',
                // 'certificationsNew',
                'containerSizes',
                'currencies',
                // 'productGroupsNew',
                'uofmQty'
            ]),
            currencyFormatPrecision () {
                return this.allCurrencies.find((currency) => currency.Currency.id === this.Purchaseorder__currency_id)?.Currency?.formatPrecision
            },
            headers() {
                let headers = [
                    {
                        id: 0,
                        text: this.$t('message.lineNo'),
                        value: 'lineNumber',
                    },
                    {
                        id: 1,
                        text: this.$t('message.certification'),
                        value: 'Salesitem.certification_id',
                    },
                    {
                        id: 1,
                        text: this.$t('message.species'),
                        value: 'Species.title',
                    },
                    {
                        id: 2,
                        text: this.$t('message.description'),
                        value: 'Spec.title',
                    },
                    {
                        id: 3,
                        text: this.$t('message.size'),
                        value: 'Size.title',
                    },
                    {
                        id: 5,
                        text: this.$t('message.poQuantity'),
                        value: 'Salesitem.poqty',
                    },
                    {
                        id: 7,
                        text: this.$t('message.costPrice'),
                        value: 'Salesitem.costprice',
                    },
                    {
                        id: 7,
                        text: this.$t('message.cost'),
                        value: 'Salesitem.amount',
                    }
                ]
                if(this.Purchaseorder__Contract__currency_id != this.Purchaseorder__currency_id) {
                    headers.push(
                        {
                            id: 8,
                            text: this.$t('message.costPrice'),
                            value: 'Salesitem.costprice_converted',
                        }
                    )
                    headers.push(
                        {
                            id: 9,
                            text: this.$t('message.costAmount'),
                            value: 'Salesitem.poamount_converted',
                        }
                    )
                    headers.push(
                        {
                            id: 10,
                            text: this.$t('message.contractQuantity'),
                            value: 'Salesitem.itemqty',
                        }
                    )
                    headers.push(
                        {
                            id: 11,
                            text: this.$t('message.actions'),
                            value: 'index',
                        }
                    )
                } else {
                    headers.push(
                        {
                            id: 9,
                            text: this.$t('message.contractQuantity'),
                            value: 'Salesitem.itemqty',
                        }
                    )
                    headers.push(
                        {
                            id: 10,
                            text: this.$t('message.actions'),
                            value: 'index',
                        }
                    )
                }
                return headers
            }
        },
        methods: {
            ...mapActions('certification', {
                getAllCertifications: 'getAllCertifications'
            }),
            ...mapActions('currency', {
                getAllCurrencies: 'getAllCurrencies'
            }),
            ...mapActions('grade', {
                getAllGrades: 'getAllGrades'
            }),
            ...mapActions('mc', {
                getAllMoistureContent: 'getAllMoistureContent'
            }),
            ...mapActions('productgroup', {
                getAllProductGroups: 'getAllProductGroups'
            }),
            ...mapActions('purchaseorderitem',{
                convertPriceAndAmount: 'convertPriceAndAmount',
                deletePurchaseOrderItem: 'deletePurchaseOrderItem',
                updateTotals: 'updateTotals'
            }),
            ...mapMutations('purchaseorderitem',{
                addOtherChargesItemRow: 'addOtherChargesItemRow',
                deletePurchasOrderItemRow: 'deletePurchasOrderItemRow'
            }),
            ...mapActions('species', {
                getAllSpecies: 'getAllSpecies'
            }),
            async deleteItem(itemIndex,itemId) {
                let _this = this
                if (await this.$root.$confirm(this.$t('message.delete'), this.$t('message.confirmations.continueDeleteItemAction'), {color: 'red'})) {
                    let promise = new Promise((resolve, reject) => {
                        let payload = {
                            index: itemIndex,
                            prefix: _this.statePrefix
                        }
                        this.deletePurchasOrderItemRow(payload)
                        resolve('ok')
                    });
                    promise.then(()=>{
                        if(itemId != null){
                            this.deletePurchaseOrderItem({
                                contract_id: this.Purchaseorder__contract_id,
                                salesitem_id: itemId}
                            )
                            .then((response)=>{
                                _this.updateTotals(_this.statePrefix)
                            })
                        } else {
                            _this.updateTotals(_this.statePrefix)
                        }
                    })
                    .finally(()=>{
                        _this.updateTotals(_this.statePrefix)
                    })
                }
            },
            formatThisNumber (value,format) {
                return numberFormat(value,format)
            },
            getCertification(certificationId) {
                let certificationObj = this.allCertifications.find(c => c.Certification.id == certificationId)
                if(certificationObj){
                    return certificationObj.Certification.title
                }
                return ''
            },
            getCurrency(currencyId) {
                let currencyObject = this.allCurrencies.find(c => c.Currency.id == currencyId)
                if(currencyObject){
                    return currencyObject.Currency.code
                }
                return ''
            },
            getProductGroup(productGroupId){
                let productGroupObj = this.allProductGroups.find(pg => pg.ProductGroup?.id != null && pg.ProductGroup.id == productGroupId)
                if(productGroupObj){
                    return productGroupObj.ProductGroup.title
                }
                return ''
            },
            getUiDescription(item) {
                let uiDescription = []

                const species = this.allSpecies.find(s => s.Species.id == item.Salesdescription.species_id)
                if(species) uiDescription.push(species.Species.title)

                if(item.Salesitem.description != null) {
                    const product = this.allProductGroups.find(p => p.ProductGroup.id == item.Salesitem.productgroup_id)
                    if (product && item.Salesitem.description.includes(product.ProductGroup.title) == false) uiDescription.push(product.ProductGroup.title)
                } else {
                    const product = this.allProductGroups.find(p => p.ProductGroup.id == item.Salesitem.productgroup_id)
                    if (product) uiDescription.push(product.ProductGroup.title)
                }

                if(item.Salesitem.description != '') uiDescription.push(item.Salesitem.description)

                const grade = this.allGrades.find(g => g.Grade.id == item.Salesitem.grade_id)
                if(grade) uiDescription.push(grade.Grade.title)

                let certification = this.allCertifications.find(c => c.Certification.id == item.Salesitem.certification_id)
                if(certification && item.Salesitem.description != null && item.Salesitem.description.includes(certification.Certification.title) == false && certification.Certification.title != 'None') uiDescription.push(certification.Certification.title)
                if(certification && item.Salesitem.description == null && certification.Certification.title != 'None') uiDescription.push(certification.Certification.title)

                let mc = this.allMoistureContent.find(mc => mc.Mc.id == item.Salesitem.mc_id)
                if(mc && item.Salesitem.description == null) uiDescription.push(mc.Mc.title)

                uiDescription = uiDescription.filter(Boolean).join(", ")
                return uiDescription
            },
            updatePurchaseOrderItemRowHandle(idx,field,value) {
                const payload = {
                    index: idx,
                    field: field,
                    prefix: this.statePrefix,
                    value: value,
                }
                const promise = new Promise((resolve, reject) => {
                    this.$store.commit('purchaseorderitem/updatePurchaseOrderItems', payload)
                    resolve('ok')
                });
                const _this = this
                promise.then(()=>{
                    this.updateTotals(this.statePrefix)
                })
            },
            uofmPrecision (uofm) {
                if(uofm) {
                    return this.uofmQty.find((u) => u.Measurement.title == uofm.trim()).Measurement.formatPrecision
                } else {
                    return '0,0.00'
                }
            },
        },
        watch: {
            Purchaseorder__currencyrate (value) {
                this.itemsCurrencyRate = value
                this.convertPriceAndAmount(this.statePrefix)
            }
        },
        created() {
            if(this.allProductGroups.length == 0) this.getAllProductGroups()
            if(this.allCertifications.length == 0) this.getAllCertifications()
            if(this.allSpecies.length == 0) this.getAllSpecies()
            if(this.allMoistureContent.length == 0) this.getAllMoistureContent()
            if(this.allGrades.length == 0) this.getAllGrades()
            if(this.allCurrencies.length == 0) this.getAllCurrencies()
        },
        mounted(){
            this.$store.watch(
                function(state) {
                    return state.appic.purchaseorderitem.update.PurchaseOrderItems
                },
                () => {
                    this.updatedPurchaseOrderUnsaved = true
                },
                { deep: true }
            )
            this.$store.watch(
                function(state) {
                    return state.appic.purchaseorderitem.current.PurchaseOrderItems
                },
                () => {
                    this.newPurchaseOrderUnsaved = true
                },
                { deep: true }
            )
            this.openForexView = false;
        }
    }
</script>

<style>
.font-16px {
    font-size: 16px !important;
    line-height: 20px;
}
.bg-lightgrey {
    background-color: #F5F5F5;
}
.force-text-left {
    text-align: left !important;
}
.v-text-field__slot input {
    text-align: right;
}
.custom.v-text-field>.v-input__control>.v-input__slot:before {
    border-style: none;
}
.custom.v-text-field>.v-input__control>.v-input__slot:after {
    border-style: none;
}
.v-input__append-inner {
    margin-left: 0px !important;
}
.v-data-table tbody td {
    vertical-align: top !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    height: 40px !important;
}
</style>